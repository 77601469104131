.repairs-card {
    &__inv-number {
        display: flex;
        min-height: 37px;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        line-height: 35px;
    }
    &__cancel-btn {
        cursor: pointer;
    }
    &__search-btn {
        cursor: pointer;
    }
}
