.checkBoxCustom {
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    border-radius: 0.25rem;
    &__box {
        border-radius: 0.25rem;
        background-color: #fff;
        border: 1px solid rgb(218, 218, 218);
        height: 16px;
        width: 16px;
        position: relative;
        display: flex;
        cursor: pointer;
        transition: 0.1s;
        &:hover {
            border-color: #00c875;
        }
    }
    &__label {
        display: flex;
        align-items: center;
        white-space: nowrap;
        height: 16px;
        left: 28px;
        bottom: 2px;
        margin: 0 16px 0 8px;
    }
    & .-disable {
        color: #b8c1cc;
    }
    & input[type='checkbox'] {
        opacity: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
    & span {
        position: absolute;
        top: -1px;
        left: -1px;
    }
    & span:before {
        content: '';
        display: block;
    }
    & input[type='checkbox']:checked + &__span:before {
        border-radius: 0.15rem;
        width: 16px;
        height: 16px;
        background-color: #00c875;
        background-image: url(../../assets/icons/checkBoksOk.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
    & input[type='checkbox'] + &__span-indeterminate:before {
        border-radius: 0.15rem;
        width: 16px;
        height: 16px;
        background-color: #00c875;
        background-image: url(../../assets/icons/checkBoksMinus.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
    & input[type='checkbox']:disabled + &__span:before {
        border-radius: 2px;
        width: 16px;
        height: 16px;
        background-color: #e4e5e8;
    }
    &.-active {
        border-color: #00c875;
    }
    &:hover {
        background: #f9fafc;
    }
    & label {
        cursor: pointer;
        height: 100%;
        width: 100%;
        margin: 0;
    }
}
.checkbox-field {
    display: flex;
    align-items: center;
}
