.supply-modal {
    &__button-add {
        width: 100%;
        height: 32px;
        border: 1px solid #96989c;
        display: flex;
        align-items: center;
        color: #787a7e;
        font-weight: 300;
        cursor: pointer;
        user-select: none;
        border-radius: 0.25rem;
        transition: 0.1s;
        padding: 0 16px;
        margin-bottom: 4px;
        &.button {
            padding: 0;
            color: #b5b7bb;
            border-color: #b5b7bb;
        }
        &:hover {
            background-color: rgb(243, 243, 243);
        }
        &:active {
            background-color: rgb(214, 213, 213);
        }
        &.disabled {
            cursor: default;
            background-color: #e5e9f0;
            border-color: #d8dbe0;
        }
    }
}
