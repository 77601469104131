.account {
    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        width: 390px;
        border: 1px solid #ced2d8;
        border-radius: 0.25rem;
        padding: 0 16px;
        overflow: auto;
        @media (max-width: 760px) {
            height: 28px;
        }
    }
    &__actions-counter {
        min-width: 81px;
        cursor: default;
        white-space: nowrap;
        margin-right: 36px;
    }
    &__actions-box {
        display: flex;
        align-items: center;
    }
    &__action {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 0 4px;
        &:hover {
            background-color: #ebedef;
        }
    }
    &__under-table-fild {
        display: flex;
        justify-content: space-between;
    }
}
