.multiselect {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    white-space: nowrap;
    user-select: none;
    &:focus {
        color: #768192;
        background-color: #fff;
        border-color: #958bef;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(50 31 219 / 25%);
    }
    &__options {
        position: absolute;
        top: 100%;
        right: 0;
        display: none;
        float: left;
        padding: 0.5rem 0.75rem;
        margin-top: 0.625rem;
        font-size: 0.875rem;
        text-align: left;
        background-clip: padding-box;
        border-radius: 0.25rem;
        color: #3c4b64;
        background-color: #fff;
        border: 1px solid #d8dbe0;
        max-height: 200px;
        overflow: auto;

        &.active {
            z-index: 1000;
            display: initial;
        }
    }
    &__option-item {
        padding: 0.5rem;
        margin-bottom: 2px;
        vertical-align: top;
        cursor: pointer;
        border-radius: 0.25rem;
        &.selected {
            background-color: #ebedef;
        }
        &:hover {
            text-decoration: none;
            color: #455164;
            background-color: #ebedef;
        }
    }
}
