.table-separators {
    & th {
        text-align: center;
        height: 100%;
    }
    & th:not(:first-child) {
        width: 15%;
    }
    & th:nth-child(1) {
        padding-left: 4px;
        text-align: start;
        width: 25%;
    }
}

.custom-table {
    color: #000000;
    max-height: calc(100vh - 210px);
    overflow: auto;

    & th {
        font-weight: lighter;
    }
    &__table {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 1130px;
        z-index: 999;
    }
    &__table-head {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        min-height: 36px;
        font-weight: 700;
        font-size: 16px;
        line-height: 14px;
        background: #fff;
        z-index: 1000;
        border-bottom: 1px solid #e9e9e9;
        & th:first-child {
            width: 25%;
        }
    }
    &__categories {
        display: flex;
        align-items: center;
        min-height: 36px;
        width: 100%;
        background: #e9e9e9;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.2px;
        font-size: 14px;
        line-height: 16px;
        padding-left: 16px;
    }
    &__fields {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 36px;
        background-color: #fff;

        & th:first-child {
            padding-left: 16px;
            display: flex;
            align-items: center;
        }
        & th:not(:first-child) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    &__fields-color {
        & tr:nth-child(odd) {
            background-color: #f7f7f7;
        }
    }
}
