.dictionary {
    &__item {
        height: calc(1.5em + 0.75rem + 2px);
        border: 1px solid #d8dbe0;
        border-radius: 0.25rem;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 16px;
        white-space: nowrap;
        &.add {
            justify-content: flex-start;
            padding-left: 8px;
            cursor: pointer;
            transition: 0.3s;
            & svg {
                transition: 0.3s;
                transform: scale(0.75);
                margin-right: 8px;
            }
            &:hover {
                border-color: #2eb85c;
                color: #2eb85c;
                & svg {
                    stroke: #2eb85c;
                    transform: scale(1);
                }
            }
        }
        &.input {
            overflow: hidden;
            & input {
                border: none;
                box-shadow: none;
                padding: 0;
                color: #3c4b64;
            }
            @media (max-width: 760px) {
                overflow: auto;
                & input[type='text'] {
                    min-width: 120px;
                }
            }
        }
        &.edit {
            border-color: #00aced;
        }
        &.create {
            border-color: #2eb85c;
            & input[type='text'] {
                min-width: 140px;
            }
        }
        &.colors {
            border: none;
            cursor: pointer;
            width: 44px;
            padding: 0;
            margin-right: 5px;
            &:hover {
                & svg {
                    stroke: #2eb85c;
                    transition: 0.3s;
                }
            }
        }
        @media (max-width: 760px) {
            height: auto;
            width: auto;
            overflow: auto;
        }
    }

    &__text {
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 760px) {
            max-width: none;
            overflow: visible;
        }
    }

    &__item-actions {
        display: flex;
        align-items: center;

        @media (max-width: 760px) {
            .dictionary__colors-common {
                margin-left: 40px;
            }
        }
    }
    &__svg-edit {
        transition: 0.3s;
        transform: scale(0.75);
        cursor: pointer;
        &:hover {
            stroke: #00aced;
            transform: scale(1);
        }
    }
    &__svg-cancel {
        transition: 0.3s;
        cursor: pointer;
        transform: scale(0.75);
        &:hover {
            stroke: #e55353;
            transform: scale(1);
        }
    }
    &__svg-plus {
        transform: scale(0.75);
        min-width: 36px;
    }

    &__svg-ok {
        transition: 0.3s;
        cursor: pointer;
        transform: scale(0.75);
        &:hover {
            stroke: #2eb85c;
            transform: scale(1);
        }
    }
    &__colors-common {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }
    &__color-box {
        display: flex;
        width: 40px;
        height: 28px;
        border: 1px solid black;
        margin-right: 6px;
        &.without {
            border: none;
            align-items: center;
        }
    }

    &__colors-box {
        display: flex;
        align-items: center;
        padding-left: 6px;
        margin-right: 20px;

        &.uid {
            & input {
                border: 1px solid black;
                height: 24px;
                width: 80px;
                padding-left: 4px;
                margin-left: 4px;
            }
        }
    }

    &__color-input {
        width: 44px;
        margin-right: 5px;
    }
}
