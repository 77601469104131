.fields-modal {
    &__body {
        & label {
            font-weight: 700;
        }
        & input {
            color: black;
        }
        & select {
            color: black;
        }
        .custom-control-label {
            font-weight: 400;
        }
    }
    &__card-field {
        padding: 16px;
        border: 1px solid rgb(196, 195, 195);
        border-radius: 0.3rem;
        margin-bottom: 8px;
    }
    &__select-box {
        display: flex;
        flex-direction: column;
    }
    &__select-list {
        margin-top: 4px;
        margin-bottom: 6px;
    }
    &__select-item-box {
        display: inline-flex;

        justify-content: space-between;
        align-items: center;
        border-radius: 0.3rem;
        border: 1px solid #d8dbe0;
        padding: 4px;
        margin-bottom: 4px;
        @media (max-width: 760px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }
    }
    &__select-item {
        margin-right: 8px;
        min-width: 87px;
        & input {
            border-radius: 0.3rem;
            border: 1px solid#d8dbe0;
            outline: none;
        }
    }
    &__delete-icon {
        cursor: pointer;
        &:hover {
            & path {
                transition: 0.1s;
                fill: #a73939;
            }
        }
    }
    &__add-icon {
        cursor: pointer;
        &:hover {
            & path {
                transition: 0.1s;
                fill: #21ac38;
            }
        }
    }
    &__plus-icon {
        cursor: pointer;
        transition: 0.3s;
        border-radius: 50%;
        margin-left: 8px;
        max-width: 44px;
        &:hover {
            fill: #fff;
            background-color: #2dca46;
        }
    }
    &__text-show {
        display: inline;
        cursor: pointer;
        transition: 0.1s;
        &:hover {
            color: rgb(53, 79, 231);
        }
    }
}
