.popover-main-accounts {
    width: auto;
    max-width: 740px;
    max-height: 480px;
    overflow: auto;
    &__item {
        padding: 6px;
    }
    @media (max-width: 640px) {
        width: 300px;
    }
}

.popover-main-page {
    width: 600px;
    max-height: 480px;
    overflow: auto;
    &__item {
        padding: 6px;
        border-bottom: 1px solid gainsboro;
    }
    &__focus-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 16px;
    }
    @media (max-width: 640px) {
        width: 300px;
    }
}

.checkbox-main-page {
    justify-content: center;
    .checkBoxCustom {
        &__box {
            &:hover {
                background-color: #f8f8f8;
                border-color: #d8dbe0;
            }
        }
        & .checkBoxCustom__span:before {
            background-color: #321fdb !important;
        }
        & .checkBoxCustom__span-indeterminate:before {
            background-color: #321fdb !important;
        }
        &.-active {
            border-color: #321fdb !important;
        }
    }
}

.svg-settings {
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        transform: rotate(90deg);
    }
}

.multiple-editing {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    @media screen and (max-width: 465px) {
        flex-direction: column;
        max-width: 250px;
    }
}
