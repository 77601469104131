.customization {
    &__item {
        display: flex;
        margin-bottom: 8px;
        margin-left: 16px;
        .checkbox-field {
            margin-right: 12px;
        }
    }
}
