.filter-modal {
    &__box {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        margin-bottom: 12px;
        & label {
            margin-bottom: 2px;
        }
    }
    &__save-btn {
        margin-left: 4px;
        cursor: pointer;
        transition: 0.1s;
        border-radius: 4px;
        &:hover {
            background-color: rgb(197, 226, 255);
        }
        &.disabled {
            user-select: none;
            cursor: default;
            & path {
                stroke: rgb(197, 226, 255);
            }
            &:hover {
                background-color: transparent;
            }
        }
    }
    &__select-item {
        display: flex;
        transition: 0.2s;
        & svg {
            transition: 0.2s;
            &:hover {
                cursor: pointer;
                & path {
                    stroke: #f15e5e;
                }
            }
        }
    }
}
