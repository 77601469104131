.console-modal {
    &__history-box {
        max-height: 180px;
        overflow: auto;
        border: 1px solid #d8dbe0;
        border-radius: 0.25rem;
        padding: 6px 12px;
    }
    &__history-item {
        margin-bottom: 8px;
        border-bottom: 1px solid rgb(228, 228, 228);
        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }
    }
    &__wait-box {
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        border: 1px solid #d8dbe0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
