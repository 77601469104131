.header-on-page {
    & button {
        margin-left: 16px;
    }
    @media (max-width: 760px) {
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        height: 46px;
        overflow: visible !important;
        padding: 12px 16px;
        & button {
            height: 24px;
            margin-left: 0;
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            margin: 0 !important;
            margin-left: 16px !important;
        }
    }
    &.main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 760px) {
            & div {
                max-height: 32px;
                display: flex;
                align-items: center;
            }
        }
    }
}

.actions-on-table {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: 760px) {
        margin-bottom: 16px;
    }
}

.custom-dropdown {
    margin: 0 !important;
    .btn-outline-secondary {
        width: 62px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 8px;
        color: #5a5a5a !important;
    }
    .dropdown-menu {
        min-width: 62px !important;
        margin-right: 2px;
        & a {
            padding: 2px 0 2px 16px;
        }
    }
    &.select {
        .btn-outline-secondary {
            width: 100% !important;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .dropdown-menu {
            min-width: 100% !important;
            @media (max-width: 760px) {
                max-width: 100%;
                overflow: hidden;
            }
        }
    }
    @media (max-width: 760px) {
        & button {
            height: 28px;
        }
    }
}

.custom-card-body {
    @media (max-width: 760px) {
        padding: 16px;
    }
}

.pagination-custom {
    .pagination {
        margin-bottom: 0;
    }
    @media (max-width: 760px) {
        & ul {
            justify-content: center !important;
            & li {
                & a {
                    padding: 4px 10px;
                }
            }
        }
    }
}

.sorter-custom-header {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}
