.moving-card {
    &__console-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        border: 1px solid #d8dbe0;
        border-radius: 0.25rem;
        color: #768192;
        margin-bottom: 4px;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 35px;
        &.disabled {
            background-color: #e5e9f0;
        }
    }
}
