// FONTS
/* roboto-300 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-v27-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/roboto-v27-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-v27-latin_cyrillic-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-300.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-300.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-v27-latin_cyrillic-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v27-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/roboto-v27-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-v27-latin_cyrillic-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-v27-latin_cyrillic-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-v27-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/roboto-v27-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-v27-latin_cyrillic-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-500.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-v27-latin_cyrillic-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/roboto-v27-latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/roboto-v27-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-v27-latin_cyrillic-italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-italic.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-v27-latin_cyrillic-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v27-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/roboto-v27-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-v27-latin_cyrillic-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-700.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-v27-latin_cyrillic-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/roboto-v27-latin_cyrillic-900.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/roboto-v27-latin_cyrillic-900.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/roboto-v27-latin_cyrillic-900.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-900.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/roboto-v27-latin_cyrillic-900.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/roboto-v27-latin_cyrillic-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
// FONTS END

// IMPORT ATOMIC
@import './molecules/index';
@import './organisms/index';
@import './pages/index';
@import './atoms/index';
@import './common/index';
//IMPORT END

body {
    font-family: Roboto, sans-serif;
}

.checkbox-custom {
    &::after {
        background-image: url(../assets/icons/slash.svg) !important;
    }
}

.button-i-custom {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    & svg {
        margin: auto;
    }
    &.delete {
        &:hover {
            & path {
                stroke: #fff;
            }
        }
    }
}

.close-modal-custom {
    cursor: pointer;
    border-radius: 0.2rem;
    &:hover {
        background: #ebedef;
    }
}

.delete-item-custom {
    cursor: pointer;
    border-radius: 0.2rem;
    margin-bottom: 2px;
    &:hover {
        background: #fcd7d79d;
    }
}

.add-item-custom {
    cursor: pointer;
    border-radius: 0.2rem;
    margin-bottom: 2px;
    &:hover {
        background: #d7fcdd9d;
    }
}

.close-notification-custom {
    cursor: pointer;
    border-radius: 0.2rem;
    &:hover {
        background: #ebedef;
    }
}

.sign-box-table {
    width: 72px;
    height: 36px;
    & img {
        max-width: 100%;
        max-height: 100%;
    }
    @media (max-width: 760px) {
        height: 28px;
    }
}

.email-table-box {
    display: inline-flex;
    align-items: center;
    & svg {
        margin-left: 4px;
    }
}

.donwload-button-custom {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.box-for-sign {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
    min-height: 140px;
    max-height: 140px;
    border: 1px solid;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
    color: #b8c1cc;
    position: relative;
    margin: 0;
    &__close {
        position: absolute;
        top: 0;
        right: 0px;
        width: 24px;
        height: 24px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 0 0.2rem 0 0.25rem;
        display: flex;
        cursor: pointer;
        & svg {
            margin: auto;
            & path {
                stroke: white;
            }
        }
    }
    &:hover {
        cursor: pointer;
        background-color: #f0f0f0;
    }
    & img {
        max-width: 100%;
        max-height: 100%;
    }
    &.photo {
        &:hover {
            cursor: default;
            background-color: transparent;
        }
    }
}

.name_of_table {
    white-space: nowrap;
}

.number-of-page-custom {
    margin: 0 !important;
    .btn-outline-secondary {
        width: 62px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 8px;
        color: #5a5a5a !important;
    }
    .dropdown-menu {
        min-width: 62px !important;
        margin-right: 2px;
        & a {
            padding: 2px 0 2px 16px;
        }
    }
}

.size-for-tables {
    min-height: calc(100vh - 273px);

    .table-responsive {
        max-height: calc(100vh - 308px);
        min-height: calc(100vh - 308px);
        overflow: auto;
        @media (max-width: 760px) {
            max-height: calc(100vh - 250px);
            min-height: calc(100vh - 250px);
        }
    }

    & table {
        width: calc(100% - 2px);

        & thead {
            height: 36px;

            & tr {
                & th {
                    text-align: center;
                    position: sticky !important;
                    top: 0 !important;
                    background-color: #fff;
                    padding: 4px !important;
                    height: 36px !important;
                    border: none;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    z-index: 1;
                    @media (max-width: 760px) {
                        font-size: 12px;
                        line-height: 14px;
                    }
                    & svg {
                        right: auto !important;
                        padding: 0 2px;
                    }
                }
            }
        }
        & tbody {
            @media (max-width: 760px) {
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
            }
            & tr {
                background: #ffffff;

                & td {
                    height: 56px !important;
                    padding: 4px 12px;
                    vertical-align: middle;
                    border: none !important;
                    border-right: 2px #e0e0e0 solid !important;
                    white-space: nowrap;
                    &:first-child {
                        border-left: 3px #e0e0e0 solid !important;
                    }
                    @media (max-width: 760px) {
                        height: 36px !important;
                    }
                }
                &:nth-child(odd) {
                    background-color: #f7f7f7;
                }
                &:nth-child(even) {
                    border-top: 1px solid #fff;
                    border-bottom: 1px solid #fff;
                }
                &:hover {
                    background-color: rgba(0, 0, 21, 0.075);
                }
            }
        }
    }

    &.modal-version {
        min-height: auto;
        .table-responsive {
            max-height: 500px;
            min-height: 500px;
            overflow: auto;
        }

        & table {
            & tbody {
                & tr {
                    & td {
                        height: 36px !important;
                    }
                }
            }
        }
    }
}

.form-control:disabled {
    background-color: #e5e9f0;
}

.body-modal-max {
    max-height: 640px;
    overflow: auto;
    @media (max-width: 760px) {
        max-height: none;
        overflow: visible;
    }
}

.reset-popover-classes {
    .tippy-box {
        max-width: none !important;
    }
    .tippy-content {
        max-width: none !important;
        font-size: 14px !important;
        line-height: 16px !important;
    }
}

.bool-custom-fild {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px !important;
    width: calc(100% + 16px);
    margin-left: -16px;
    color: #fff;
    border: 1px solid #fff;
}

.style-for-header-custom {
    @media (max-width: 760px) {
        .custom-header {
            font-size: 12px;
            line-height: 14px;
            font-weight: 300;
            height: 46px;
            overflow: visible !important;
            padding: 12px 16px;

            & div {
                max-width: 100%;
                white-space: normal !important;
                margin: 0 !important;
            }
            & button {
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
                margin: 0 !important;
                margin-left: 16px !important;
            }
            .stc {
                padding: 0;
            }
        }
        .card-body {
            padding: 16px;
            @media (max-width: 760px) {
                .account__actions {
                    height: 28px;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 300;
                    .account__actions-counter {
                        margin-right: 10px;
                    }
                }
            }
        }
        .size-for-tables {
            min-height: calc(100vh - 216px);

            .table-responsive {
                max-height: calc(100vh - 252px);
                min-height: calc(100vh - 252px);
            }

            .pagination {
                margin-bottom: 0;
                margin-top: 2px;
                @media (max-width: 760px) {
                    margin-top: 6px;
                    & li {
                        & a {
                            padding: 6px 10px;
                        }
                    }
                }
            }
        }
        .align-items-center {
            margin-bottom: 4px !important;
            & button {
                font-size: 12px;
                line-height: 14px;
                font-weight: 300;
            }
        }
    }
}
.c-main {
    @media (max-width: 760px) {
        padding-top: 16px;
        height: calc(100vh - 57px);
    }
}

.style-statistic-page {
    .card {
        overflow: auto;
    }
    .card-header {
        padding: 16px;
        min-width: 540px;
    }
    .card-body {
        padding: 16px;
        min-width: 540px;
    }
}

.search-custom {
    display: flex;
    align-items: center;
    padding-right: 8px;
    transition: 0.1s;
    border: 1px solid #d8dbe0;
    border-radius: 0.25rem;
    position: relative;
    & input {
        border: none;
        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    &.active {
        outline: 0;
        border-color: #958bef;
        box-shadow: 0 0 0 0.2rem rgb(50 31 219 / 25%);

        & path {
            stroke: #958bef;
        }
    }
    &__warning {
        position: absolute;
        top: -18px;
        left: 2px;
        font-size: 12px;
        line-height: 18px;
        color: #ffc700;
    }
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;

    &::after {
        display: block;
        content: '';
        border: 4px solid #b8c1cc;
        border-bottom: 4px solid transparent;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        animation: spin 1s linear infinite;
    }
    &.sm {
        height: auto;
        width: auto;
        &::after {
            width: 20px;
            height: 20px;
            border-width: 2px;
        }
    }
}

@media (min-width: 760px) {
    /* полоса прокрутки (скроллбар) */
    ::-webkit-scrollbar {
        width: 8px; /* ширина для вертикального скролла */
        height: 8px; /* высота для горизонтального скролла */
        background-color: #dbdbdb;
    }

    /* ползунок скроллбара */
    ::-webkit-scrollbar-thumb {
        border-radius: 9em;
        background-color: #9facc7;
        box-shadow: inset 1px 1px 10px #f3faf7;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #8190b1;
    }
}

.dark-mode-style {
    @media (min-width: 760px) {
        /* полоса прокрутки (скроллбар) */
        ::-webkit-scrollbar {
            width: 8px; /* ширина для вертикального скролла */
            height: 8px; /* высота для горизонтального скролла */
            background-color: rgb(57, 59, 71);
        }

        /* ползунок скроллбара */
        ::-webkit-scrollbar-thumb {
            background-color: rgb(42, 43, 54);
            border-radius: 9em;
            box-shadow: inset 1px 1px 10px #f3faf7;
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: #253861;
        }
    }

    .c-app {
        --primary: #4638c2;
        --secondary: #4c4f54;
        --success: #45a164;
        --info: #4799eb;
        --warning: #e1a82d;
        --danger: #d16767;
        --light: #6c6e7e;
        --dark: #0e0e15;
        color: rgba(255, 255, 255, 0.87);
        background-color: #181924;
        --color: rgba(255, 255, 255, 0.87);
        --elevation-base-color: 0, 0, 0;
    }

    .c-sidebar {
        color: rgba(255, 255, 255, 0.87);
        background: #2a2b36;
    }

    .c-header {
        background: #2a2b36;
        border-bottom-color: #181924;
    }

    .c-header-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        &:hover {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }

    .card-header {
        background-color: #282933;
        border-color: rgba(255, 255, 255, 0.075);
    }

    .card {
        background-color: rgb(36, 37, 47);
        border-color: rgb(40, 40, 47);
    }
    .card-header {
        background-color: #282933;
        border-color: rgba(255, 255, 255, 0.075);
    }

    .table {
        color: rgba(255, 255, 255, 0.87);

        thead {
            background-color: #282933;
        }

        tr {
            background-color: #282933;

            th {
                background-color: rgb(36, 37, 47);
            }

            td {
                border-right: 2px #34353a solid !important;
            }
            td:first-child {
                border-left: 3px #34353a solid !important;
            }
        }

        tr:nth-child(even) {
            background-color: rgb(36, 37, 47);
            border-top: 1px solid rgb(36, 37, 47);
            border-bottom: 1px solid rgb(36, 37, 47);
            &:hover {
                background-color: rgb(46, 47, 58);
            }
        }
        tr:nth-child(odd) {
            background-color: rgb(43, 43, 51);
            &:hover {
                background-color: rgb(46, 47, 58);
            }
        }

        thead {
            tr {
                background-color: #24252f !important;
            }
        }
    }

    .button-custom.grey {
        color: #fff;
        background-color: #6c6e7e !important;
        path {
            fill: #fff;
        }
        &:hover {
            background-color: #5a5c69 !important;
        }
    }

    .dropdown-toggle {
        color: #fff !important;
        background-color: #343639 !important;
        border-color: #2e3033 !important;
    }

    .dropdown-menu {
        background-color: #34353e;
        border-color: #2e3033;
        a {
            color: #fff !important;
            &:hover {
                background-color: #404147;
            }
        }
    }

    .active .page-link {
        background-color: #321fdb !important;
    }
    .page-link {
        color: rgba(255, 255, 255, 0.87) !important;
        background-color: transparent !important;
        border-color: rgba(255, 255, 255, 0.075) !important;
        &:hover {
            background-color: rgba(255, 255, 255, 0.075) !important;
        }
    }

    .modal-content {
        background-color: #3d3e47;
        border-color: rgba(0, 0, 0, 0.2);
    }
    .modal-header {
        border-color: #787a7e;
    }
    .fields-modal__card-field {
        border-color: #787a7e;
    }
    .close-modal-custom {
        &:hover {
            background-color: #60616d;
        }
    }
    .custom-control-label {
        path {
            fill: white !important;
        }
    }

    .form-control {
        color: rgba(255, 255, 255, 0.9) !important;
        background-color: rgba(255, 255, 255, 0.05);
        border-color: rgba(255, 255, 255, 0.09);
    }
    .import__label {
        &:hover {
            background-color: rgba(255, 255, 255, 0.05);
        }
    }

    .custom-select {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgba(255, 255, 255, 0.05);
        border-color: rgba(255, 255, 255, 0.2);
        option {
            color: rgba(255, 255, 255, 0.87);
            background-color: rgb(36, 37, 47);
        }
    }

    .dashboard-page__stelaj-item {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgba(255, 255, 255, 0.05);
        border-left-color: rgba(255, 255, 255, 0.2);
        border-right-color: rgba(255, 255, 255, 0.2);
        border-top-color: rgba(255, 255, 255, 0.2);

        &:hover {
            border-color: rgba(255, 255, 255, 0.87);
        }
    }
    .dashboard-page__tooltip-custom {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgb(36, 37, 47);
    }

    .dashboard-page__tooltip-button {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgb(55, 63, 55);
        border: 1px solid rgb(129, 129, 129);

        &:hover {
            background-color: rgb(94, 105, 94);
        }
    }

    .dashboard-page__title {
        color: white;
    }

    .nav-item {
        a {
            color: white;
        }
    }
    .btn-secondary {
        color: #fff;
        background-color: #4c4f54;
        border-color: #4c4f54;
    }
    .dashboard-page__button {
        background-color: #323538;
    }

    .dashboard-page__stelaj-item-m {
        color: rgba(255, 255, 255, 0.87);
        background-color: rgba(255, 255, 255, 0.05);
        border-left-color: rgba(255, 255, 255, 0.2);
        border-bottom-color: rgba(255, 255, 255, 0.2);
        border-top-color: rgba(255, 255, 255, 0.2);

        &:hover {
            border-color: rgba(255, 255, 255, 1);
        }
    }

    .supply-modal__button-add {
        &:hover {
            background-color: rgba(255, 255, 255, 0.05);
        }
    }

    .box-for-sign {
        &:hover {
            color: rgba(255, 255, 255, 0.87);
            background-color: rgba(255, 255, 255, 0.05);
        }
    }

    .dictionary__item {
        border-color: rgb(129, 129, 129);
    }

    .custom-table {
        color: rgba(255, 255, 255, 0.87);
    }
    .custom-table__categories {
        background-color: rgb(46, 45, 45);
        font-weight: 900;
        letter-spacing: 0.4px;
    }
    .custom-table__table-head {
        background-color: #24252f;
        border-bottom-color: #24252f;
    }
    .custom-table__fields-color {
        tr:nth-child(even) {
            background-color: rgb(36, 37, 47) !important;
            &:hover {
                background-color: rgb(46, 47, 58) !important;
            }
        }
        tr:nth-child(odd) {
            background-color: rgb(43, 43, 51) !important;
            &:hover {
                background-color: rgb(46, 47, 58) !important;
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
