.dashboard-page {
    &__wrapper {
        width: 100%;
        overflow: auto;
        height: 724px;
    }
    &__title {
        display: flex;
        align-items: center;
        height: 35px;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #2e3a59;
        margin: 8px 0;
        white-space: nowrap;
        min-width: 600px;
        .account__actions {
            max-width: 370px;
            font-weight: 400;
            margin-left: 16px;
            background-color: #fff;
        }
    }
    &__stelaj-box {
        display: flex;
        flex-wrap: wrap;
        max-width: 1500px;
        min-width: 1500px;
    }
    &__stelaj-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 70px;
        border: 1px solid rgb(199, 199, 199);
        border-bottom: 8px solid black;
        background-color: rgb(236, 236, 236);
        position: relative;
        &:hover {
            border-color: black;
        }
        &:nth-child(15n),
        &:nth-child(15n + 14) {
            .dashboard-page__tooltip-custom {
                top: 36px;
                left: -160%;
            }
        }
    }
    &__svg-ok {
        cursor: pointer;
        position: absolute;
        top: 1px;
        left: 2px;
        background-color: rgb(248, 247, 247);
        border-radius: 6px;
        border: 1px solid green;
    }
    &__svg-wait {
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 1px;
        left: 2px;
    }
    &__buttons-box {
        margin-top: 8px;
    }
    &__button {
        &.active {
            border: 1px solid gray;
            background-color: rgb(180, 180, 180);
        }
    }
    &__wrapper-m {
        width: 100%;
        max-height: calc(100vh - 162px);
        overflow: auto;
    }
    &__stelaj-box-m {
        display: flex;
        flex-flow: column wrap;
        max-height: 700px;
        min-height: 700px;
        border: 4px solid black;
        max-width: 608px;
        min-width: 608px;
    }
    &__black-line {
        height: 4px;
        width: 100px;
        background-color: black;
    }
    &__stelaj-item-m {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 38px;
        border: 1px solid rgb(199, 199, 199);
        background-color: #fff;
        position: relative;
        &.active {
            background-color: blanchedalmond;
        }
        &:hover {
            border-color: black;
        }
        &.leftLine {
            &::before {
                content: '';
                display: block;
                position: absolute;
                left: -2px;
                width: 4px;
                height: 38px;
                background-color: rgb(121, 121, 121);
            }
        }
    }
    &__stelaj-item-empty {
        width: 100px;
        height: 38px;
        border: 1px solid rgb(199, 199, 199);
        background-color: #fff;
        background: rgba(0, 0, 0, 0) url(../../assets/icons/slash-x.svg) no-repeat;
        background-size: 100% 100%;
    }
    &__box-counter-page {
        min-width: 600px;
        max-width: 600px;
        overflow: auto;
    }
    &__tooltip-custom {
        position: absolute;
        top: 36px;
        left: 60px;
        background-color: rgb(201, 242, 252);
        border: 1px solid rgb(151, 184, 192);
        z-index: 10000;
        font-weight: 300;
        padding: 8px 16px;
        border-radius: 0.25rem;
        white-space: nowrap;
    }
    &__tooltip-title {
        margin-bottom: 16px;
    }
    &__tooltip-body {
        margin-bottom: 16px;
    }
    &__tooltip-footer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    &__tooltip-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 136px;
        height: 22px;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        background-color: rgb(213, 248, 213);
        border: 1px solid rgb(129, 129, 129);
        border-radius: 0.25rem;
        cursor: pointer;
        transition: 0.1s;
        &:hover {
            background-color: rgb(195, 216, 195);
            border: 1px solid rgb(122, 122, 122);
        }
    }
}
