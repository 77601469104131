.button-custom {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 35px;
    min-width: 35px;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.5;
    text-align: center;
    transition: 0.1s;

    @media (max-width: 760px) {
        min-width: auto;
    }

    &:focus {
        outline: none;
    }

    &.grey {
        color: #4f5d73;
        background-color: #ebedef;
        &:hover {
            background-color: #d6dade;
            border-color: #cfd4d8;
        }
        &:active {
            color: #4f5d73;
            background-color: #cfd4d8;
            border-color: #c8cdd3;
        }
    }

    &.blue {
        color: #fff;
        background-color: #00aced;
        &:hover {
            background-color: #0090c7;
        }
        &:active {
            background-color: #0087ba;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgb(38 184 240 / 50%);
        }
    }

    &.green {
        color: #fff;
        background-color: #aad450;
        &:hover {
            background-color: #9bcc32;
        }
        &:active {
            background-color: #93c130;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgb(156 194 85 / 50%);
        }
    }

    &.red {
        color: #e55353;
        border: 1px solid #e55353;
        background-color: #fff;
        &:hover {
            background-color: #e55353;
            border-color: #e55353;
            & path {
                stroke: #fff;
            }
        }
        &:active {
            background-color: #e55353;
            border-color: #e55353;
            & path {
                stroke: #fff;
            }
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgb(229 83 83 / 50%);
        }
    }

    @media (max-width: 760px) {
        font-size: 12px;
        line-height: 14px;
        font-weight: 300;
        height: 28px;
    }
}
