.statistics {
    &__hidden-table {
        position: relative;
        .size-for-tables {
            min-height: auto;
            .table-responsive {
                max-height: 568px;
                min-height: auto;
                overflow: auto;
                @media (max-width: 760px) {
                    max-height: auto;
                    min-height: auto;
                }
            }
            & table {
                margin: 0;
            }

            & nav {
                display: inline !important;
                min-height: 35px;
            }
        }

        .dropup {
            position: absolute;
            bottom: 0px;
            right: 16px;
            transition: 0.3s;
        }
    }
}
