.import {
    &__input-file {
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        border: 1px solid #d8dbe0;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        font-weight: 400;
        padding-left: 12px;
        overflow: hidden;
    }
    &__input-name {
        width: 72%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__input-size {
        width: 28%;
        text-align: end;
        padding-right: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    &__input-close {
        cursor: pointer;
        width: 35px;
        height: 100%;
        display: flex;
        background-color: #f0f0f0;
        transition: 0.2s;
        & svg {
            margin: auto;
        }
        &:hover {
            & path {
                stroke: #f0f0f0;
            }
            background-color: #e4adad;
        }
    }
    &__label {
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        border: 1px solid #d8dbe0;
        border-radius: 0.25rem;
        cursor: pointer;
        color: #768192;
        padding: 6px 12px;
        margin-bottom: 0;
        &:hover {
            background-color: #f0f0f0;
        }
    }
    &__headers-box {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        max-width: 100%;
        overflow: auto;
        padding-bottom: 8px;
    }
    &__headers {
        display: flex;
        & select {
            width: 174px;
            border-radius: 0;
            color: #3c4b64;
            &:first-child {
                border-radius: 0.25rem 0 0 0;
            }
            &:last-child {
                border-radius: 0 0.25rem 0 0;
            }
        }
    }
    &__values-column {
        display: flex;
        flex-direction: column;
    }
    &__values {
        display: flex;
        .import__value {
            border: 1px solid #d8dbe0;
            border-top: none;
        }
        &:last-child {
            .import__value {
                border-radius: 0;
                &:first-child {
                    border-radius: 0 0 0 0.25rem;
                }
                &:last-child {
                    border-radius: 0 0 0.25rem 0;
                }
            }
        }
    }
    &__value {
        height: calc(1.5em + 0.75rem + 2px);
        width: 174px;
        min-width: 174px;
        white-space: nowrap;
        padding: 7px 7px 7px 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #768192;
    }
}
